import React from 'react';
import styled from 'styled-components';
import { PointCard } from './PointCard';
import { Summary } from './Summary';
import { Dashboard as DashboradProps } from './types';

const StyleDashboard = styled.div`
  padding: 21px;
  flex: 1 0 auto;
  max-width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export default function Dashboard({ data }: DashboradProps) {
  return (
    <StyleDashboard>
      <Summary
        total_data={data.total_data}
        total_point_online={data.total_point_online}
        total_point_offline={data.total_point_offline}
      />
      {data.points?.map((point, idx) => (
        <PointCard key={idx} {...point} />
      ))}
    </StyleDashboard>
  );
}
