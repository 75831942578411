import React, { createContext, useCallback, useState } from 'react';

const MenuContext = createContext<{
  showMenu: boolean;
  toggleMenu: () => void;
}>({ showMenu: true, toggleMenu: () => {} });
MenuContext.displayName = 'MenuContext';

function MenuProvider(props) {
  const [show, setShow] = useState(true);

  const toggleMenu = useCallback(() => {
    setShow((show) => !show);
  }, []);

  return <MenuContext.Provider value={{ showMenu: show, toggleMenu }} {...props} />;
}

function useMenu() {
  const context = React.useContext(MenuContext);
  if (context === undefined) {
    throw new Error(`useMenu must be used within a MenuContext`);
  }

  return context;
}

export { MenuProvider, useMenu };
