import DashboardAsync from '@Organisms/Dashboard/DashboardAsync';
import PointDetailPage from '@Organisms/PointDetailPage';
import PointsMapAsync from '@Organisms/PointsMap/PointsMapAsync';
import SideMenuAsync from '@Organisms/SideMenu/SideMenuAsync';
import { UserReport } from '@Organisms/UserReport';
import { LoadScript } from '@react-google-maps/api';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'styled-components/macro';
import Layout from './layout';

function AuthenticatedApp() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyAAEHZBevKkq140wfda5TKWKiiaIvpaTRk">
      <Switch>
        <Route exact path="/map">
          <Layout sidebar={<SideMenuAsync />}>
            <div
              style={{
                width: '100%',
                height: '100%',
                flex: 1,
              }}
            >
              <PointsMapAsync />
            </div>
          </Layout>
        </Route>
        <Route exact path="/">
          <Layout sidebar={<SideMenuAsync />}>
            <DashboardAsync />
          </Layout>
        </Route>
        <Route path="/point/:pointId">
          <Layout sidebar={<SideMenuAsync />}>
            <PointDetailPage />
          </Layout>
        </Route>
        <Route path="/report">
          <Layout sidebar={<SideMenuAsync />}>
            <div className="container-fluid px-4 py-4">
              <UserReport />
            </div>
          </Layout>
        </Route>
      </Switch>
    </LoadScript>
  );
}

export default AuthenticatedApp;
