import React, { createContext, useCallback } from 'react';
import { useAsync } from 'react-async';
import { queryCache } from 'react-query';
import * as auth from '../auth';
import { client } from '../client';

const fetchMe = async () => {
  let user = null;

  const token = await auth.getToken();

  if (token) {
    const data = await client('points', { token, data: null });
    queryCache.setQueryData('points', data.data, {
      staleTime: 50000,
      cacheTime: 100000,
    });
    return { token };
  }
  return user;
};

const AuthContext = createContext<{
  user: any;
  login: (authInfo: { email: string; password: string }) => Promise<any>;
  logout: () => Promise<any>;
}>({ user: null, login: auth.login, logout: auth.logout });
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
  const { data: user, error, isPending: isLoading, setData } = useAsync({
    promiseFn: fetchMe,
  });

  const isError = !!error;

  const login = useCallback(
    (form) => auth.login(form).then((user) => setData(user)),
    [setData]
  );

  const logout = useCallback(
    () => auth.logout().then(() => setData(null)),
    [setData]
  );

  const value = React.useMemo(() => ({ user, login, logout }), [
    login,
    user,
    logout,
  ]);

  if (isLoading) {
    return <div> Full page loading... </div>;
  }

  if (isError) {
    return <div> Full page error fallback</div>;
  }

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useClient() {
  const { user } = useAuth();
  const token = user?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
}

export { AuthProvider, useAuth, useClient };
