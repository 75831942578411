import { Table } from '@Components/Table';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import 'styled-components/macro';
import format from 'date-fns/format';
import { useFetchUserReport } from './useFetchUserReport';
import { DateRange } from '@blueprintjs/datetime';
import DateRangeInput from '@Components/DateRangeInput';
import { useEffect } from 'react';
import { Button } from 'rmwc';
import { useReportDownload } from './useReportDownload';
import PageTitle from "@Atomics/PageTitle";

function UserReport() {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [dateRangeOption, setDateRangeOption] = useState<{
    start: any;
    end: any;
  }>();

  const {
    // isLoading,
    // isError,
    // error,
    latestData,
    resolvedData: fetchedData,
    isFetching: isLoading,
    refetch,
  } = useFetchUserReport({
    ...dateRangeOption,
  });

  useEffect(() => {
    refetch();
  }, [refetch, dateRangeOption]);

  const refetchHandler = () => {
    setDateRangeOption({
      start:
        dateRange && format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
      end:
        dateRange && format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });
  };

  const [filteredAttributes, setFilteredAttributes] = useState<string[] | null>(
    null
  );

  const dataTypeOptions = useMemo(() => {
    return [
      {
        label: 'Giá trị nhỏ nhất',
        value: 'min',
      },
      {
        label: 'Giá trị lớn nhất',
        value: 'max',
      },
      {
        label: 'Giá trị trung bình',
        value: 'avg',
      },
      {
        label: 'Giá trị cuối cùng',
        value: 'last',
      },
    ];
  }, []);

  const [dataType, setDataTypes] = useState<
    string | null // 'min' | 'max' | 'avg' | 'last'
  >(() => dataTypeOptions.find((o) => o.value === 'last')?.value || null);

  const columns = useMemo(
    () => [
      {
        Header: 'Tên trạm',
        accessor: 'name',
      },
      ...(dataType === 'last'
        ? [
            {
              Header: 'Thời gian',
              accessor: 'time',
            },
          ]
        : []),
      ...Object.values(fetchedData?.data?.attributes ?? [])
        .filter((a) => {

          return (
            !filteredAttributes ||
            filteredAttributes.length === 0 ||
            filteredAttributes.includes('' + a.id)
          );
        })
        .map((a) => ({
          Header: a.name + ' (' +  a.unit + ')',
          accessor: `${a.id}`,
          Cell: ({ value }) => (value.value || value.value === 0 ? String(value.value) : ''),
        })),
    ],
    [dataType, fetchedData, filteredAttributes]
  );

  const data = useMemo(
    () =>
      (fetchedData?.data.points ?? []).map((i) => {
        const datum = {};

        for (const column of columns) {
          datum[`${column.accessor}`] = {
            is_warning:
              i.attributes[column.accessor] &&
              i.attributes[column.accessor][dataType || 'last']?.is_warning,
            value:
              i.attributes[column.accessor] &&
              i.attributes[column.accessor][dataType || 'last']?.value_no_unit,
          };
        }

        return {
          ...datum,
          name: i.name,
          time: i.last_send_data,
        };
      }),
    [fetchedData, columns, dataType]
  );

  const pageCount = 1;

  const options = useMemo(() => {
    return Object.values(fetchedData?.data.attributes || {}).map((i) => ({
      label: i.name,
      value: `${i.id}`,
    }));
  }, [fetchedData]);

  const [handleDownloadClick] = useReportDownload();

  const onSelectAttributesChange = useCallback(
    (selectedOptions) =>
      setFilteredAttributes(
        selectedOptions ? selectedOptions.map((s) => s.value) : selectedOptions
      ),
    []
  );

  const onDataTypeChange = useCallback(
    (selectedOption) =>
      setDataTypes(selectedOption ? selectedOption.value : selectedOption),
    []
  );

  const canDownload = fetchedData?.data?.can_download;

  return fetchedData ? (
    <section className="row">
      <PageTitle>Báo cáo tổng hợp</PageTitle>
      <div className="col">
        <div
          css={`
            margin-top: 20px;
            flex: 1 0 auto;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 15px 20px 7px 20px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
              0 1px 20px 0 rgba(0, 0, 0, 0.07),
              0px 1px 11px 0px rgba(0, 0, 0, 0.07);
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              width: 100%;
              flex-wrap: wrap;
              padding-bottom: 10px;
            `}
          >
            <Button
              css={`
                margin-right: 10px;
                margin-top: 10px;
              `}
              raised
              theme={['primaryBg', 'onPrimary']}
              label="Lọc"
              ripple={false}
              onClick={refetchHandler}
            ></Button>
            <DateRangeInput
              dateRange={dateRange}
              setDateRange={setDateRange}
              css={`
                margin-right: 10px;
                margin-top: 10px;
              `}
            />
            <Select
              css={`
                min-width: 250px;
                margin-right: 10px;
                margin-top: 10px;
              `}
              options={options}
              onChange={onSelectAttributesChange}
              placeholder="Lọc theo thông số"
              isMulti
            />
            <Select
              css={`
                min-width: 250px;
                margin-top: 10px;
              `}
              options={dataTypeOptions}
              defaultValue={dataTypeOptions.find((o) => o.value === 'last')}
              onChange={onDataTypeChange}
              placeholder="Loại báo cáo"
            />
            {canDownload ? (<Button
              css={`
                margin-right: 10px;
                margin-top: 10px;
                position: absolute;
                right: 50px;
              `}
              danger raised
              label="Download"
              ripple={false}
              onClick={() =>
                handleDownloadClick({
                  start:
                    dateRange &&
                    format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                  end:
                    dateRange &&
                    format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                  type: dataType,
                  attributes: filteredAttributes,
                })
              }
            ></Button>) : ('')}
          </div>

          <Table
            showPaginate={false}
            columns={columns}
            data={data}
            pageCount={pageCount}
            fetchData={() => {}}
            loading={isLoading && !latestData}
          />
        </div>
      </div>
    </section>
  ) : null;
}

export default UserReport;
