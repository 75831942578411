import { useQuery } from 'react-query';
import { useClient } from '../../context/auth-context';
import { LastDataResponse } from './types';

function useLastData({ pointId }) {
  const client = useClient();

  const result = useQuery(`point/${pointId}/last-data`, async () => {
    const res = await client(`point/${pointId}/last-data`, {});
    return res as LastDataResponse;
  }, {
    enabled: pointId
  });

  return { ...result, data: result.data ?? undefined };
}

export { useLastData };
