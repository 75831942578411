import { Navbar } from '@Organisms/Navbar';
import { MenuProvider } from 'context/menu-context';
import React from 'react';
import styled from 'styled-components';

const StyledBody = styled.section`
  display: flex;
`;

const StyleMain = styled.div`
  margin-top: var(--nav-size);
  margin-right: 0;
  height: calc(100vh - var(--nav-size));
  max-height: calc(100vh - var(--nav-size));
  flex-grow: 1;
  overflow-y: auto;
`;

function Layout(props: any) {
  return (
    <MenuProvider>
      <Navbar />
      <StyledBody>
        {props.sidebar}
        <StyleMain >{props.children}</StyleMain>
      </StyledBody>
    </MenuProvider>
  );
}

export default Layout;
