import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface PointsMapProps {
  data: {
    markers: {
      lat: number | string;
      lng: number | string;
      device_code: string;
    }[];
  };
}

function PointMap({ data }: PointsMapProps) {
  return data.markers && data.markers.length ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: +data.markers[0]?.lat,
          lng: +data.markers[0]?.lng,
        }}
        zoom={12}
        options={{ disableDefaultUI: true, scrollwheel: false, }}
      >
        {(data.markers ?? []).map((point) => (
          <Marker
            key={point.device_code}
            position={{ lat: +point.lat, lng: +point.lng }}
          />
        ))}
      </GoogleMap>
  ) : null
}

export default PointMap;
