import 'styled-components/macro';
import Text from '@Atomics/Text';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
} from '@rmwc/data-table';
import React, { useCallback, useEffect } from 'react';
import { usePagination, useTable } from 'react-table';
import Paginate from './Paginate';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';

export function Table({
  showPaginate,
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1, pageSize: 10 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const onPageChangeHandler = useCallback(
    (e) => {
      gotoPage(e.selected + 1);
    },
    [gotoPage]
  );

  return (
    <section
      css={`
        flex: 1;
        position: relative;
        width: 100%;
      `}
    >
      {loading && (
        <div
          css={`
            position: absolute;
            z-index: 1000;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <CircularProgress size="large" />
        </div>
      )}
      <DataTable
        {...getTableProps()}
        css={`
          border: 0;
          width: 100%;
        `}
      >
        <DataTableContent>
          <DataTableHead>
            {headerGroups.map((headerGroup) => (
              <DataTableRow
                {...headerGroup.getHeaderGroupProps()}
                css={`
                  height: 43px;
                `}
              >
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps()}
                    css={`
                      font-weight: 700;
                      font-size: 13px;
                      border: none;
                      letter-spacing: 1px;
                      text-transform: uppercase;
                    `}
                  >
                    <Text
                      css={`
                        color: #1b55e2;
                      `}
                    >
                      {column.render('Header')}
                    </Text>
                  </DataTableHeadCell>
                ))}
              </DataTableRow>
            ))}
          </DataTableHead>
          <DataTableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <DataTableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <DataTableCell {...cell.getCellProps()}>
                        <Text
                          css={`
                            border: none;
                            color: #000;
                            font-size: 14px;
                            letter-spacing: 1px;
                            ${cell?.value?.is_warning ? 'color: red; font-weight:900' : ''};
                          `}
                        >
                          {cell.render('Cell')}
                        </Text>
                      </DataTableCell>
                    );
                  })}
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
      {showPaginate ? (
        <Paginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPageChangeHandler}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      ) : null}
    </section>
  );
}
