import React, {useMemo, useState} from 'react';
import styled from 'styled-components/macro';
import {useFetchPointDetail} from './useFetchPointDetail';
import Text from '@Atomics/Text';
import PointMap from './PointMap';
import {useClient} from '../../context/auth-context';
import {queue} from '../../common/notification';

const AttributePropertyLine = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AttributeProperty = styled(Text)`
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 15px;
  color: #00a65a;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 9px;
`;

const AttributePropertyValue = styled(Text)`
  letter-spacing: 0px;
  font-weight: 600;
  font-size: 14px;
  color: #000;
`;

const StyledButton = styled.button`
margin-right: 10px;
background-color: #00a65a;
color: white;
padding: 10px;
border-radius: 5px;
`;

function PointInfo({ pointId }: { pointId: number }) {
  const { data } = useFetchPointDetail({ pointId });
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };
  const client = useClient();

  const handleUploadClick = async ({ pointId, selectedFiles }) => {
    if (selectedFiles === undefined || selectedFiles?.length === 0) {
      queue.notify({
        body: 'Vui lòng chọn file',
        dismissesOnAction: true,
        icon: 'check',
      });
      return;
    }

    let fname = selectedFiles[0].name;
    let re = /(\.xlsx)$/i;
    if (!re.exec(fname)) {
      queue.notify({
        body: 'Vui lòng upload file excel. Hoặc tải file mẫu ở trên',
        dismissesOnAction: true,
        icon: 'check',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFiles[0]);

    const res = await client(`point/${pointId}/import`, {
      data: formData,
      method: 'POST',
      isFile: true,
    });

    queue.notify({
      body: res?.message ? res?.message : 'Lỗi vui lòng thử lại',
      dismissesOnAction: true,
      icon: 'check',
    });
    // @ts-ignore
    document.getElementById('file').value = '';
  };

  const info = useMemo(() => {
    return [
      {
        label: 'Tên',
        value: data?.data.name ?? '',
      },
      {
        label: 'Vị trí',
        value: data?.data.address ?? '',
      },
      {
        label: 'Thông số',
        value:
          Object.values(data?.data.attributes ?? {})
            .map((attribute) => attribute.name + ' (' + attribute.unit + ')')
            .join(', ') ?? '',
      },
      {
        label: 'Kiểu trạm',
        value: data?.data.point_type ?? '',
      },
      {
        label: 'Loại trạm',
        value: data?.data.point_data_type ?? '',
      },
    ];
  }, [data]);

  const imgs = useMemo(() => {
    return data?.data.images && data?.data.images[0];
  }, [data]);

  return (
    <section className="row mt-4">
      <div className="col">
        <div
          css={`
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
              0 1px 20px 0 rgba(0, 0, 0, 0.07),
              0px 1px 11px 0px rgba(0, 0, 0, 0.07);

            display: flex;
            overflow: hidden;
            height: 700px;
          `}
        >
          <div
            css={`
              padding: 1.25rem;
              flex: 0 1 40%;
            `}
          >
            {info.map((i, idx) => (
              <AttributePropertyLine key={idx}>
                <AttributeProperty>{i.label}</AttributeProperty>
                <AttributePropertyValue>{i.value}</AttributePropertyValue>
              </AttributePropertyLine>
            ))}
            {data?.data?.sample_file_link !== '' && data?.data?.can_import_point_data &&
            data?.data?.show_import ? (
              <div css={`
              margin-bottom: 10px;
            `}>
                <div>
                  <AttributePropertyLine>
                    <AttributeProperty>Tải file import mẫu</AttributeProperty>
                    <AttributePropertyValue>
                      <a href={data?.data?.sample_file_link}>
                        Click vào đây để tải file
                      </a>
                    </AttributePropertyValue>
                  </AttributePropertyLine>
                </div>
                <div>
                  <input
                    id="file"
                    type="file"
                    onChange={selectFile}
                    placeholder="File"
                    required
                  />
                  <StyledButton
                    onClick={() =>
                      handleUploadClick({
                        pointId: pointId,
                        selectedFiles: selectedFiles,
                      })
                    }
                  >
                    Upload dữ liệu
                  </StyledButton>
                </div>
              </div>
            ) : (
              ''
            )}
            {imgs && (
              <div>
                <img
                  css={`
                    max-width: 100%;
                    height: 250px;
                    object-fit: contain;
                  `}
                  src={imgs.fullUrl}
                  alt={imgs.alt}
                />
              </div>
            )}
          </div>
          <div
            css={`
              flex: 1;
            `}
          >
            <PointMap
              data={{
                markers: data?.data
                  ? [
                      {
                        lat: data?.data.lat,
                        lng: data?.data.lng,
                        device_code: data.data.device_code,
                      },
                    ]
                  : [],
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PointInfo;
