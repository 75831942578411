import { DateRange, DateRangePicker } from '@blueprintjs/datetime';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import format from 'date-fns/format';
import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from '../common/useOnClickOutside';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import { ReactComponent as Calendar } from './calendar.svg';

const DateInput = styled.input`
  border: 1px solid #bfc9d4;
  color: #3b3f5c;
  font-size: 14px;
  padding: 8px 10px;
  letter-spacing: 1px;
  padding: 0.55rem 1.25rem;
  border-radius: 6px;
  margin-right: 6px;
`;

function DateRangeInput({ dateRange, setDateRange, ...props }) {
  const [show, setShow] = useState(false);
  const calendarButtonRef = useRef(null);
  const dateRangePickerRef = useRef(null);

  const [internalDateRange, setInternalDateRange] = useState<DateRange>();

  useOnClickOutside(
    calendarButtonRef,
    () => setShow(false),
    dateRangePickerRef
  );

  return (
    <div
      css={`
        position: relative;
      `}
      {...props}
    >
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        {
          <>
            <Button
              ripple={false}
              onClick={() => setShow(!show)}
              ref={calendarButtonRef}
            >
              <Calendar />
            </Button>
          </>
        }
        {dateRange && (
          <>
            <DateInput
              readOnly
              type="text"
              value={format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss')}
            />
            <DateInput
              readOnly
              type="text"
              value={format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss')}
            />
          </>
        )}
      </div>
      {show && (
        <div
          ref={dateRangePickerRef}
          css={`
            top: 40px;
            position: absolute;
            z-index: 1000;
            border-radius: 8px;
            background: #fff;
            padding: 15px;
            box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
              0 1px 20px 0 rgba(0, 0, 0, 0.07),
              0px 1px 11px 0px rgba(0, 0, 0, 0.07);
            border: 1px solid #e0e6ed;
          `}
        >
          <DateRangePicker
            allowSingleDayRange={true}
            timePickerProps={{
              defaultValue: new Date(),
              precision: 'second',
              showArrowButtons: true,
            }}
            maxDate={new Date()}
            locale="vi"
            shortcuts={createDefaultShortcuts(true, true, true)}
            value={internalDateRange}
            localeUtils={MomentLocaleUtils}
            onChange={(dateRange) => {

              setInternalDateRange(dateRange);
            }}
          />
          <div
            css={`
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
            `}
          >
            <div>
              <Button raised onClick={() => {setDateRange(internalDateRange);setShow(false)}}>
                Chọn
              </Button>
              <Button onClick={() => setShow(false)}>Đóng</Button>
            </div>
            <Button outlined onClick={() => setDateRange(null)}>
              Clean
            </Button>
          </div>
        </div>
      )}
      {/* <MomentDateRange withTime={props.timePrecision !== undefined} range={this.state.dateRange} /> */}
    </div>
  );
}

function createDefaultShortcuts(
  allowSingleDayRange: boolean,
  hasTimePrecision: boolean,
  useSingleDateShortcuts: boolean
) {
  const today = new Date();
  const makeDate = (action: (d: Date) => void) => {
    const returnVal = clone(today);
    action(returnVal);
    returnVal.setDate(returnVal.getDate() + 1);
    return returnVal;
  };

  const tomorrow = makeDate(() => null);
  const yesterday = makeDate((d) => d.setDate(d.getDate() - 2));
  const oneWeekAgo = makeDate((d) => d.setDate(d.getDate() - 7));
  const oneMonthAgo = makeDate((d) => d.setMonth(d.getMonth() - 1));
  const threeMonthsAgo = makeDate((d) => d.setMonth(d.getMonth() - 3));
  const sixMonthsAgo = makeDate((d) => d.setMonth(d.getMonth() - 6));
  const oneYearAgo = makeDate((d) => d.setFullYear(d.getFullYear() - 1));
  const twoYearsAgo = makeDate((d) => d.setFullYear(d.getFullYear() - 2));

  const singleDayShortcuts =
    allowSingleDayRange || useSingleDateShortcuts
      ? [
          createShortcut('Hôm nay', [
            today,
            hasTimePrecision ? tomorrow : today,
          ]),
          createShortcut('Hôm qua', [
            yesterday,
            hasTimePrecision ? today : yesterday,
          ]),
        ]
      : [];

  return [
    ...singleDayShortcuts,
    createShortcut(useSingleDateShortcuts ? '1 tuần trước' : 'Tuần trước', [
      oneWeekAgo,
      today,
    ]),
    createShortcut(useSingleDateShortcuts ? '1 tháng trước' : 'Tháng trước', [
      oneMonthAgo,
      today,
    ]),
    createShortcut(useSingleDateShortcuts ? '3 tháng trước' : '3 tháng trước', [
      threeMonthsAgo,
      today,
    ]),
    // Don't include a couple of these for the single date shortcut
    ...(useSingleDateShortcuts
      ? []
      : [createShortcut('Past 6 months', [sixMonthsAgo, today])]),
    createShortcut(useSingleDateShortcuts ? '1 nắm trước' : 'Năm ngoái', [
      oneYearAgo,
      today,
    ]),
    ...(useSingleDateShortcuts
      ? []
      : [createShortcut('Past 2 years', [twoYearsAgo, today])]),
  ];
}

function clone(d: Date) {
  return new Date(d.getTime());
}

function createShortcut(label: string, dateRange: DateRange) {
  return { dateRange, label };
}

export default DateRangeInput;
