import {useClient} from 'context/auth-context';
import {useMutation} from 'react-query';

function useStartCam() {
  const client = useClient();

  return useMutation(async ({ camUid }: any) => {
    return await client(`camera/${camUid}/startImage`, {
      method: 'GET',
    });
  });
}

export { useStartCam };
