import { usePaginatedQuery } from 'react-query';
import { useClient } from '../../context/auth-context';
import { PointTableResponse } from './types';

function useFetchPointTable({ pointId, start, end, page }: any) {
  const client = useClient();

  const startQuery = start ? `start=${start}` : '';
  const endQuery = end ? `end=${end}` : '';

  const result = usePaginatedQuery(
    ['table', pointId, startQuery, endQuery, page],
    async () => {
      const res = await client(
        `point/${pointId}/table?${startQuery}&${endQuery}&page=${page}`,
        {}
      );
      return res as PointTableResponse;
    }
  );

  return { ...result, data: result?.data ?? {} };
}

export { useFetchPointTable };
