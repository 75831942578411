import React from 'react';
import styled from 'styled-components';

export const StyledTypography = styled.span`
  font-family: var(--font-family);
  font-size: 20px;
  color: #2b2b2b;
  font-weight:900;
  padding-left:15px
`;

const PageTitle: React.FC = ({ children, ...rest }) => {
  return <StyledTypography {...rest}>{children}</StyledTypography>;
};

export default PageTitle;
