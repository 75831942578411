import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4lang_vi_VN from '@amcharts/amcharts4/lang/vi_VN';

import React, { useEffect, useMemo, useRef } from 'react';
import 'styled-components/macro';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dataviz);

interface PointChartProps {
  chartId: string;
  data: {
    seriesData: ({
      [id: string]: string | number;
    } & { date: Date })[];
    attributes: {
      id: number;
      name: string;
      color?: string;
      unit: string;
      warnings?: number[];
      type: 'LineSeries' | 'ColumnSeries';
    }[];
  };
  labelsEnabled: boolean;
}

function PointChartAmChart({
  chartId,
  data: { seriesData, attributes },
  labelsEnabled,
}: PointChartProps) {
  const chartRef = useRef<any>(null);

  const warningLines = useMemo(() => {
    return attributes
      .map((att) =>
        (att.warnings ?? []).map((w) => {
          return {
            id: att.id,
            value: w,
          };
        })
      )
      .reduce((acc, cur) => (acc ?? []).concat(cur ?? []), []);
  }, [attributes]);

  useEffect(() => {
    const config = {
      colors: { step: 2 },
      language: {
        locale: am4lang_vi_VN,
      },
      cursor: { lineX: { disable: false }, lineY: { disable: false } },
      grid: { disable: true },
      scrollbarX: {},
      xAxes: createXAxes({
        start: 0.8,
        end: 1,
        dateFormat: 'dd-MM-yyyy HH:mm:ss',
      }),
      yAxes: createYAxes([
        ...attributes.map((att) => ({
          valueField: `${att.id}`,
          gridDisabled: true,
          labelsEnabled,
        })),
        // ...warningLines.map((w, idx) => ({
        //   valueField: chartId + 'warning' + w.id + idx,
        //   gridDisabled: true,
        // })),
      ]),
      series: [
        ...attributes.map((att) =>
          createChartConfig({ ...att, id: `${att.id}`, hiddenInLegend: false })
        ),
        ...(warningLines ?? []).map((line, idx) => {
          // trend.dataFields.valueY = "value";
          // trend.dataFields.dateX = "date";
          // trend.strokeWidth = 2
          // trend.stroke = trend.fill = am4core.color("#c00");
          // trend.data = data

          // {
          //   date: seriesData[0].date,
          //   [chartId + 'warning' + line.id + idx]: line.value,
          // },
          // {
          //   date: seriesData[seriesData.length - 1].date,
          //   [chartId + 'warning' + line.id + idx]: line.value,
          // },

          return {
            dataFields: {
              valueY: chartId + 'warning' + line.id + idx,
              dateX: 'date',
            },
            stroke: 'red',
            data: [
              {
                date: seriesData[0].date,
                [chartId + 'warning' + line.id + idx]: line.value,
              },
              {
                date: seriesData[seriesData.length - 1].date,
                [chartId + 'warning' + line.id + idx]: line.value,
              },
            ],
          };
        }),
      ],
      data: seriesData.map((datum) => {
        const newDatum = {
          ...datum,
        };

        warningLines.forEach((w, idx) => {
          newDatum[chartId + 'warning' + w.id + idx] = w.value;
        });

        return newDatum;
      }),
      legend: {},
    };

    chartRef.current = am4core.createFromConfig(
      config,
      `chart${chartId}`,
      am4charts.XYChart
    );

    if (!chartRef.current) {
      return;
    }

    return () => {
      chartRef.current.dispose();
    };
  }, [attributes, chartId, labelsEnabled, seriesData, warningLines]);

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        padding: 15px 20px 7px 20px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
          0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
        height: 552px;
      `}
    >
      <div
        id={`chart${chartId}`}
        style={{ width: '100%', height: '500px' }}
      ></div>
    </div>
  );
}

interface ChartConfig {
  type: 'LineSeries' | 'ColumnSeries';
  name: string;
  color?: string;
  unit: string;
  id: string;
  data?: any;
  hiddenInLegend: boolean;
}

function createChartConfig({
  type,
  color = '#3c3c3c',
  unit,
  name,
  id,
  data,
  hiddenInLegend,
}: ChartConfig) {
  return {
    showOnInit: false,
    smoothing: 'monotoneX',
    stroke: color,
    type,
    name: name,
    strokeWidth: 2,
    dataFields: { valueY: id, dateX: 'date' },
    yAxis: id,
    tooltipText: `{valueY} ${unit}`,
    hiddenInLegend,
    tooltip: {
      getFillFromObject: false,
      background: { fill: color },
    },
    legendSettings: {
      labelText: `{name} ${unit}`,
    },
    ...(data
      ? {
          data,
        }
      : {}),
    ...(type === 'ColumnSeries'
      ? {
          columns: {
            width: '50%',
            template: { fillOpacity: 0.7 },
            propertyFields: {
              strokeDasharray: 'dashLength',
              fillOpacity: 'alpha',
            },
          },
          fill: color,
        }
      : {}),
  };
}

function createXAxes({
  start,
  end,
  dateFormat,
}: {
  start: number;
  end: number;
  dateFormat;
}) {
  return [
    {
      type: 'DateAxis',
      dataFields: { dateX: 'date' },
      dateFormatter: { dateFormat: dateFormat ?? 'dd-MM-yyyy HH:mm:ss' },
      tooltipDateFormat: 'dd-MM-yyyy HH:mm:ss',
      start: start ?? 0.8,
      end: end ?? 1,
      keepSelection: true,
    },
  ];
}

interface YAxisConfig {
  valueField: string;
  gridDisabled?: boolean;
  labelsEnabled;
}

function createYAxes(series: YAxisConfig[]) {
  return [
    ...series.map((data) => ({
      type: 'ValueAxis',
      value: data.valueField,
      id: data.valueField,
      renderer: {
        labels: {
          template: {
            disabled: data.labelsEnabled,
          },
        },
        grid: {
          disabled: data.gridDisabled,
        },
      },
    })),
  ];
}

export default PointChartAmChart;
