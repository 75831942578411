import Text from '@Atomics/Text';
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useLastData } from './useLastData';
import { reverse } from 'ramda';
import format from 'date-fns/format';

import 'styled-components/macro';

const colors = {
  1: 'var(--purple)',
  2: 'var(--red)',
  3: 'var(--green)',
  4: 'var(--blue)',
};

export function AttributeCharts({ pointId }: { pointId: number }) {
  const {
    isLoading,
    // isError,
    // error,
    data,
    // latestData,
    // isFetching,
  } = useLastData({ pointId });

  const attributes = useMemo(() => {
    const atts = Object.values(data?.data?.point?.attributes ?? {});
    return atts.map((att) => ({
      ...att,
      data:
        data?.data?.point_data.map((i) => [
          i.timestamp * 1000,
          i.data[att.id],
        ]) ?? [],
      time: data?.data?.point_data[0]?.created_at_vn,
    }));
  }, [data]);


  return isLoading ? (
    <section
      css={`
        height: 149px;
      `}
      className="row"
    />
  ) : (
    <section
      className="row"
      css={`
        margin-top: 20px;
      `}
    >
      {attributes.map((attribute) => (
        <div className="col" key={attribute.code}>
          <div
            css={`
              padding: 15px 20px 7px 20px;
              border-radius: 8px;
              background: #fff;
              position: relative;
              box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
                0 1px 20px 0 rgba(0, 0, 0, 0.07),
                0px 1px 11px 0px rgba(0, 0, 0, 0.07);
              /* .apexcharts-tooltip-title {
                display: none;
              } */

              .apexcharts-xaxistooltip-bottom {
                display: none;
              }

              .apexcharts-svg.apexcharts-zoomable.hovering-zoom {
                cursor: default;
              }
            `}
          >
            <div
              css={`
                position: absolute;
                width: 100%;
              `}
            >
              <div>
                <Text
                  css={`
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0;
                    color: #515365;
                  `}
                >{`${attribute.name} (${attribute.unit})`}</Text>
              </div>
              <div>
                <Text
                  css={`
                    position: absolute;
                    right: 40px;
                    top: 0;
                    color: var(--primary);
                    font-size: 24px;
                    letter-spacing: 1px;
                    margin-bottom: 0;
                    font-weight: 700;
                  `}
                >{`${
                  attribute?.data && attribute?.data[attribute.data.length -1]
                    ? attribute?.data[attribute.data.length -1][1]
                    : ''
                }`}</Text>
              </div>
            </div>
            <div
              css={`
                padding-top: 12px;
                width: 100%;
              `}
            >
              <Chart
                options={{
                  chart: {
                    animations: {
                      enabled: false
                    },
                    offsetY: 10,
                    toolbar: {
                      show: false,
                    },
                    dropShadow: {
                      enabled: false,
                      enabledOnSeries: undefined,
                      top: 0,
                      left: 0,
                      blur: 3,
                      color: colors[attribute.id],
                      opacity: 0.35,
                    },
                  },
                  tooltip: {
                    x: {
                      show: true,
                      formatter: (value) =>
                        format(value, 'dd-MM-yyyy HH:mm:ss'),
                    },
                  },
                  stroke: {
                    curve: 'smooth',
                    width: 3,
                  },
                  grid: {
                    show: false,
                  },
                  xaxis: {
                    show: false,
                    labels: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    crosshairs: {
                      show: false,
                    },
                  },
                  yaxis: {
                    show: false,
                    crosshairs: {
                      show: false,
                    },
                    forceNiceScale: true,
                    min: Math.min(...attribute.data.map((d) => d[1])),
                    max: Math.max(...attribute.data.map((d) => d[1])),
                  },
                }}
                series={[
                  {
                    name: attribute.name,
                    data: reverse(attribute.data),
                    color: attribute.color
                      ? attribute.color
                      : colors[attribute.id],
                  },
                ]}
                type={attribute.type_chart === 1 ? 'bar' : 'line'}
                width="100%"
                height="100"
              />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}
