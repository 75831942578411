import React from 'react';
import { ReactComponent as ErrorFixing } from './error-fixing.svg';
import { ErrorBoundary } from 'react-error-boundary';
import 'styled-components/macro';
import Text from '@Atomics/Text';

function FallbackComponent() {
  return (
    <div
      css={`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-directioin: column;
      `}
    >
      <ErrorFixing width={150} />
      <Text
        css={`
          color: var(--mdc-theme-text-secondary-on-background);
        `}
      >
        Something went wrong...
      </Text>
    </div>
  );
}

export default function ErrorFallback({ children }) {

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  );
}
