import {useAuth} from './context/auth-context';
import React, {useCallback} from 'react';
import {LoginForm} from '@Components/LoginForm';
import Button from '@Atomics/Button';
import Text from '@Atomics/Text';
import styled from 'styled-components';

const LoginButton = styled(Button)`
    background-color: var(--primary);
    color: white;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11px 14px;
    font-size: 16px;
    letter-spacing: 2px;
    color: #fff !important;
    background-color: #1b55e2!important;
    border-color: #1b55e2;
    box-shadow: 0 10px 20px -10px #1b55e2;
    margin-top:20px;
    
`;

const LoginButtonText = styled(Text)`
  color: white;
`;

const FormContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0 auto;
`

const FormWrap = styled.div`
    max-width: 480px;
    margin: 0 auto;
    min-width: 311px;
    min-height: 100%;
    width: 100%;
    height: 100vh;
    align-self: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
}
`

const FormContent = styled.div`
    display: block;
    width: 100%;
    padding: 25px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #e0e6ed;
`

const FormSubTitle = styled.a`
    color: #0e3fb7;
`

const FormTitle = styled.h2`
    color: #1b55e2;
`

function UnauthenticatedApp() {
    const {login} = useAuth();

    const handleSubmit = useCallback(
        async (loginInfo) => {
            await login(loginInfo);
        },
        [login]
    );

    return (
        <FormContainer>
            <FormWrap>
                <FormContent>
                    <FormTitle>HỆ THỐNG GIÁM SÁT NGUỒN NƯỚC TỰ ĐỘNG</FormTitle>
                    <FormSubTitle>Vui lòng đăng nhập để tiếp tục.</FormSubTitle>
                    <LoginForm
                        onSubmit={handleSubmit}
                        submitButton={
                            <LoginButton type="submit">
                                <LoginButtonText>Login</LoginButtonText>
                            </LoginButton>
                        }
                    />
                </FormContent>
            </FormWrap>
        </FormContainer>
    );
}

export default UnauthenticatedApp;
