import { useQuery } from 'react-query';
import { useClient } from '../../context/auth-context';
import { PointChartResponse } from './types';

function useFetchPointChart({ pointId }) {
  const client = useClient();

  const result = useQuery(`point/${pointId}/chart`, async () => {
    const res = await client(`point/${pointId}/chart`, {});
    return res as PointChartResponse;
  });


  return { ...result, data: result?.data };
}

export { useFetchPointChart };
