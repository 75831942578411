import { useQuery } from 'react-query';
import { useClient } from '../context/auth-context';

function useFetchPoints() {
  const client = useClient();

  const result = useQuery('points', async () => {
    const res = await client('points', {});
    return res?.data;
  });


  return { ...result, data: result?.data ?? [] };
}

export { useFetchPoints };
