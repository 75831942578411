import React, {useEffect, useMemo, useState} from 'react';
import {Button} from 'rmwc';
import 'styled-components/macro';
import {useStartCam} from './useStartCam';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

export default function Camera({
  camUid,
  camName,
}: {
  camUid: string;
  camName: string;
}) {
  const [startCam, {data: camData}] = useStartCam();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (camData) {
      setLoading(false);
    }
  });

  return useMemo(
      () => (
          <div className="mt-6 relative">
            {camData ? (
                <div
                    css={`
                  position: relative;
                `}>
                  <Button
                      css={`
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    z-index: 9;
                    height: 25px;
                    transform: translate(-50%, -50%);
                `}
                      raised
                      label={`Cập nhật ${camName}`}
                      ripple={false}
                      onClick={() => {
                        setLoading(true);
                        startCam({camUid});
                      }}
                  />
                  <OwlCarousel
                      rewind={true}
                      items={1}
                      loop={true}
                      autoplay={true}
                      freeDrag={false}
                      pullDrag={false}
                      touchDrag={false}
                      mouseDrag={false}
                      autoplayTimeout={1000}
                      autoplayHoverPause={true}
                      animateIn={true}
                      animateOut={true}
                  >
                    {camData.data.map((image, idx) => (
                        <div key={idx}>
                          <a
                              title={
                                'Đang dừng. Click vào để lưu ảnh. Di chuyển chuột ra ngoài để tiếp tục'
                              }
                              target="_blank"
                              href={image}
                          >
                            <img className="img" src={image}/>
                          </a>
                        </div>
                    ))}
                  </OwlCarousel>
                </div>
            ) : (
                <div
                    css={`
              width: 100%;
              height: 450px;
              background-color: #000;
              position: relative;
            `}
                >
                  {loading ? (
                      <div
                          css={`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index:999;
                `}
                      ><Loader
                          type="ThreeDots"
                          color="#3b82f6"
                          height={100}
                          width={100}
                      /></div>
                  ) : (
                      <Button
                          css={`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                `}
                          raised
                          label={`${camName}`}
                          ripple={false}
                          onClick={() => {
                            setLoading(true);
                            startCam({camUid});
                          }}
                      />
                  )}
                </div>
            )}
          </div>
      ),
      [camData, camName, camUid, startCam, loading]
  );
}
