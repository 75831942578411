import { DateRange } from '@blueprintjs/datetime';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import format from 'date-fns/format';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'rmwc';
import 'styled-components/macro';
import DateRangeInput from '../../components/DateRangeInput';
import './datetime.scss';
import { Table } from '@Components/Table';
import { useFetchPointTable } from './useFetchPointTable';
import Select from 'react-select';
import { usePointDataDownload } from './usePointDataDownload';

function PointDataTableAsync({ pointId }: { pointId: number }) {
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState<DateRange>();

  const [dateRangeOption, setDateRangeOption] = useState<{
    start: any;
    end: any;
  }>();

  const {
    // isLoading,
    // isError,
    // error,
    resolvedData,
    latestData,
    isFetching: isLoading,
    refetch,
  } = useFetchPointTable({
    pointId,
    page,
    ...dateRangeOption,
  });

  const [filteredAttributes, setFilteredAttributes] = useState<number[] | null>(
    null
  );

  const fetchData = useCallback(({ pageIndex, pageCount }) => {
    setPage(pageIndex);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Thời gian',
        accessor: 'created_at_vn',
      },
      ...Object.values(resolvedData?.data?.point?.attributes ?? [])
        .filter(
          (a) =>
            !filteredAttributes ||
            filteredAttributes.length === 0 ||
            filteredAttributes.includes(a.id)
        )
        .map((a) => ({
          Header: a.name + ' (' + a.unit + ')',
          accessor: `${a.id}`,
          Cell: ({ value }) => (value.value !== undefined ? String(value.value) : ''),
        })),
    ],
    [resolvedData, filteredAttributes]
  );

  const data = useMemo(
    () =>
      (resolvedData?.data?.point_data?.data ?? []).map((i) => {
        const datum = {};

        for (const column of Object.keys(i.data)) {
          datum[`${column}`] = {
            is_warning:
              resolvedData?.data?.point?.attributes &&
              i.data[column] >
                (resolvedData?.data?.point?.attributes[column].warnings ??
                  [])[0]?.value,
            value: i.data[column],
          };
        }
        return {
          created_at_vn: i.created_at_vn,
          ...datum,
        };
      }),
    [resolvedData]
  );

  const pointData = resolvedData?.data?.point_data;
  const canDownload = resolvedData?.data?.can_download;

  const pageCount = useMemo(() => pointData?.last_page ?? 0, [pointData]);

  const refetchHandler = () => {
    setDateRangeOption({
      start:
        dateRange && format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
      end:
        dateRange && format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });
  };

  useEffect(() => {
    refetch();
  }, [refetch, dateRangeOption]);

  const options = useMemo(() => {
    return Object.values(resolvedData?.data.point.attributes || {}).map(
      (i) => ({
        label: i.name,
        value: i.id,
      })
    );
  }, [resolvedData]);

  const onSelectAttributesChange = useCallback(
    (selectedOptions) =>
      setFilteredAttributes(
        selectedOptions ? selectedOptions.map((s) => s.value) : selectedOptions
      ),
    []
  );

  const [handleDownloadClick] = usePointDataDownload();

  return (
    <section className="row">
      <div className="col">
        <div
          css={`
            margin-top: 21px;
            flex: 1 0 auto;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 15px 20px 7px 20px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
              0 1px 20px 0 rgba(0, 0, 0, 0.07),
              0px 1px 11px 0px rgba(0, 0, 0, 0.07);
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              width: 100%;
              padding-bottom: 10px;
              position: relative;
            `}
          >
            <Button
              css={`
                margin-right: 10px;
              `}
              raised
              theme={['primaryBg', 'onPrimary']}
              label="Lọc"
              ripple={false}
              onClick={refetchHandler}
            ></Button>
            <DateRangeInput dateRange={dateRange} setDateRange={setDateRange} />
            <Select
              css={`
                min-width: 250px;
              `}
              placeholder="Lọc theo thông số"
              options={options}
              onChange={onSelectAttributesChange}
              isMulti
            />
            {canDownload ? <Button
              css={`
                margin-left: auto;
              `}
              danger
              raised
              label="Download"
              ripple={false}
              onClick={() =>
                handleDownloadClick({
                  start:
                    dateRange &&
                    format(dateRange[0] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                  end:
                    dateRange &&
                    format(dateRange[1] || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                  pointId: pointId,
                })
              }
            ></Button> : ('')}
          </div>
          {useMemo(
            () =>
              resolvedData && (
                <Table
                  showPaginate={true}
                  columns={columns}
                  data={data}
                  pageCount={pageCount}
                  fetchData={fetchData}
                  loading={isLoading && !latestData}
                />
              ),
            [
              resolvedData,
              columns,
              data,
              pageCount,
              fetchData,
              isLoading,
              latestData,
            ]
          )}
        </div>
      </div>
    </section>
  );
}

export default PointDataTableAsync;
